import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_FUEL_COST } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemFuelCostMaybe = props => {
  const { lineItems, intl } = props;
  const translationKey = 'OrderBreakdown.fuelCostHourly';

  const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_FUEL_COST);

  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return unitPurchase && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemFuelCostMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemFuelCostMaybe;
