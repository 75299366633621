import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes, LINE_ITEM_CAPTAIN_SERVICE_FEE } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemCaptainServiceFeeMaybe = props => {
  const { lineItems, intl } = props;
  const translationKey = 'OrderBreakdown.captainServiceFee';

  const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_CAPTAIN_SERVICE_FEE);
  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return unitPurchase && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemCaptainServiceFeeMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCaptainServiceFeeMaybe;
