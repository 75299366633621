import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { listingsApi } from '../../util/api';

// Pagination page size might need to be dynamic on responsive page layouts
const RESULT_PAGE_SIZE = 10;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/BoatListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/BoatListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/BoatListingsPage/FETCH_LISTINGS_ERROR';

export const UPDATE_LISTING_COMMISSION_REQUEST =
  'app/ListingPage/UPDATE_LISTING_COMMISSION_REQUEST';
export const UPDATE_LISTING_COMMISSION_SUCCESS =
  'app/ListingPage/UPDATE_LISTING_COMMISSION_SUCCESS';
export const UPDATE_LISTING_COMMISSION_ERROR = 'app/ListingPage/UPDATE_LISTING_COMMISSION_ERROR';

export const APPROVE_LISTING_REQUEST = 'app/ListingPage/APPROVE_LISTING_REQUEST';
export const APPROVE_LISTING_SUCCESS = 'app/ListingPage/APPROVE_LISTING_SUCCESS';
export const APPROVE_LISTING_ERROR = 'app/ListingPage/APPROVE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchListingsQueryParams: null,
  fetchListingsInProgress: false,
  fetchListingsError: null,
  approveListingInProgress: false,
  approveListingError: null,
  updateListingCommissionInProgress: false,
  updateListingCommissionError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const boatListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsQueryParams: payload.queryParams,
        fetchListingsInProgress: true,
        fetchListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        fetchListingsInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListingsError: payload,
      };

    case APPROVE_LISTING_REQUEST:
      return { ...state, approveListingInProgress: true, approveListingError: null };
    case APPROVE_LISTING_SUCCESS:
      return { ...state, listingId: payload, approveListingInProgress: false };
    case APPROVE_LISTING_ERROR:
      return { ...state, approveListingError: payload, approveListingInProgress: false };

    case UPDATE_LISTING_COMMISSION_REQUEST:
      return {
        ...state,
        updateListingCommissionInProgress: true,
        updateListingCommissionError: null,
      };
    case UPDATE_LISTING_COMMISSION_SUCCESS:
      return { ...state, commissionData: payload, updateListingCommissionInProgress: false };
    case UPDATE_LISTING_COMMISSION_ERROR:
      return {
        ...state,
        updateListingCommissionError: payload,
        updateListingCommissionInProgress: false,
      };

    default:
      return state;
  }
};

export default boatListingsPageReducer;

// ================ Action creators ================ //

export const fetchListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const fetchListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const approveListingRequest = () => ({ type: APPROVE_LISTING_REQUEST });
export const approveListingSuccess = payload => ({
  type: APPROVE_LISTING_SUCCESS,
  payload,
});
export const approveListingError = error => ({
  type: APPROVE_LISTING_ERROR,
  payload: error,
});

export const updateListingCommissionRequest = () => ({ type: UPDATE_LISTING_COMMISSION_REQUEST });
export const updateListingCommissionSuccess = payload => ({
  type: UPDATE_LISTING_COMMISSION_SUCCESS,
  payload,
});
export const updateListingCommissionError = error => ({
  type: UPDATE_LISTING_COMMISSION_ERROR,
  payload: error,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const fetchListings = queryParams => async (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return listingsApi
    .fetch({ params })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingsError(storableError(e)));
      throw e;
    });
};

export const approveListing = listingId => dispatch => {
  dispatch(approveListingRequest());

  return listingsApi
    .approve({ listingId })
    .then(response => dispatch(approveListingSuccess(response.data)))
    .catch(error => dispatch(approveListingError(storableError(error))));
};

export const updateListingCommission = (commission, listingId) => dispatch => {
  dispatch(updateListingCommissionRequest());

  return listingsApi
    .updateCommission({ commission, listingId })
    .then(response => dispatch(updateListingCommissionSuccess(response.data)))
    .catch(error => dispatch(updateListingCommissionError(storableError(error))));
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  return fetchListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author'],
  });
};
